import React, { useEffect } from "react"
import Footer from "../components/footer"
import "../scss/style.scss"
import SEO from "../components/seo"
// import bgImage from "../images/contact-bg.jpg"
import ContactModal from "../components/contact-modal"
import Top from "../components/qbo/top"
const QBODisconnted = () => {
  return (
    <div className="h-full font-robo qbo-disc">
      <SEO title="Luca+ QuickBooks disconnected page" einvoice={true} />
      <Top />
      <section className="contact-page">
        <div className="contact-page__banner">
          <div className="contact-page__overlay"></div>
          <div className="title">QuickBooks Disconnected</div>
        </div>

        <div className="container">
          <div className="contact-page__modal-wrapper">
            <ContactModal />
          </div>
        </div>
      </section>
      <section className=" main text-center">
        <div className="title2 mx-auto ">QuickBooks Disconnected</div>
        <div className="desc mt-16 mx-auto ">
          Your QuickBooks integration has been disconnected. You will no longer
          to be able to send invoices directly to your QuickBooks account from
          LUCA Plus. <br /> <br /> If you’d like to re-connect LUCA Plus and
          your QuickBooks account,
          <a href="#" className="text-mind underline ml-1">
            click here to view our help guide.
          </a>
        </div>
      </section>
      <Footer simple={true} />
    </div>
  )
}

export default QBODisconnted
